import './style.scss';
import { SubscribeForm } from '../SubscribeForm';
import Images from '../../resources/imgs';

export default function MainContent() {
  return (
    <div className="content">
      <div className="main-infos">
        <h1>Front End? Back End? Pra que escolher?</h1>
        <h2>
          Aprenda com <b>profissionais líderes na indústria</b> a como
          desenvolver aplicações mobile Android e iOS de ponta a ponta. Tudo
          isso utilizando tecnologias populares como <b>Flutter e Node.js</b>.
          Bora!
        </h2>
      </div>
      <SubscribeForm />
      <div className="instructors">
        <span>{'{'}</span>
        <a href="https://www.linkedin.com/in/pedrobelfort/" target="blank">
          <div className="instructor">
            <img src={Images.Ins1} alt="Pedro Belfort"></img>
            <div className="instructor__name">
              Pedro Belfort<img src={Images.Linkedin} alt="LinkedIn"></img>
            </div>
          </div>
        </a>
        <a href="https://www.linkedin.com/in/yagotome/" target="blank">
          <div className="instructor">
            <img src={Images.Ins2} alt="Yago Tomé"></img>
            <div className="instructor__name">
              Yago Tomé<img src={Images.Linkedin} alt="LinkedIn"></img>
            </div>
          </div>
        </a>
        <span>{'}'}</span>
      </div>
    </div>
  );
}
