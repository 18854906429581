import './style.scss';
import { useCallback, useMemo, useState } from 'react';
import useRecaptcha, { RecaptchaPolicies } from '../../hooks/useRecaptcha';
import { gtag } from '../../utils/analytics';

const { REACT_APP_EMAIL_SUBSCRIPTION_API_URL } = process.env;

const formDataInitialState = {
  email: {
    value: '',
    required: true,
    pristine: true,
    valid: false,
    pattern: /^[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9-]+(\.[a-z0-9-]+)*$/,
  },
};

export const SubscribeForm = () => {
  const [formData, setFormData] = useState(formDataInitialState);
  const isFormValid = useMemo(
    () => Object.values(formData).every(({ valid }) => valid),
    [formData]
  );
  const onInputChange = useCallback(
    (event) => {
      const { target } = event;

      const field = { ...formData[target.name] };
      field.value = target.value;
      field.valid = !(
        (field.required && !target.value) ||
        (field.pattern && !field.pattern.test(target.value))
      );
      field.pristine = false;

      setFormData((prevState) => ({
        ...prevState,
        [target.name]: { ...prevState[target.name], ...field },
      }));
    },
    [formData, setFormData]
  );
  const { getRecaptchaToken } = useRecaptcha({ action: 'subscribe_presale' });
  const [formState, setFormState] = useState('initial');

  const onSubmitCallback = useCallback(
    async (event) => {
      gtag('event', 'submit_presale_form');

      event.preventDefault();
      if (!isFormValid) {
        console.error('invalid form', formData);
        return;
      }

      try {
        setFormState('loading');

        const recaptchaToken = await getRecaptchaToken();
        const res = await fetch(REACT_APP_EMAIL_SUBSCRIPTION_API_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...Object.entries(formData).reduce(
              (data, [key, field]) => ({ ...data, [key]: field.value }),
              {}
            ),
            recaptchaToken,
          }),
        });
        if (!res.ok) {
          throw new Error('Failed to send message', res.body);
        }

        setFormState('success');
        setFormData(formDataInitialState);
      } catch (err) {
        console.error(err);
        setFormState('error');
      }
    },
    [isFormValid, formData, getRecaptchaToken, setFormData]
  );

  return (
    <div className="cta-wrapper">
      <div className="cta-title">
        Saia na frente e receba a ementa do curso. Vamos te atualizar sempre que
        tivermos novidades.
      </div>
      <form className="cta" onSubmit={onSubmitCallback}>
        <div className="email">
          <input
            name="email"
            type="email"
            placeholder="meu@email.com"
            required
            onInput={onInputChange}
            value={formData.email.value}
          />
        </div>
        <button className="subscribe-button" disabled={formState === 'loading'}>
          {formState !== 'loading' ? (
            'Inscrever-se'
          ) : (
            <svg
              className="loading-spinner"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.4878 3.52047C25.1729 8.20801 25.1708 15.806 20.4833 20.491C15.7958 25.176 8.19778 25.174 3.51276 20.4865C0.313118 17.2851 -0.813003 12.5558 0.60049 8.25597C0.872781 7.47357 1.7278 7.06005 2.51017 7.33237C3.27245 7.59767 3.68813 8.41858 3.45081 9.19008C1.89705 13.9115 4.46493 18.9986 9.18638 20.5524C13.9078 22.1062 18.9949 19.5383 20.5487 14.8168C22.1025 10.0954 19.5346 5.0083 14.8132 3.45451C12.9937 2.8557 11.0306 2.85305 9.20949 3.44691C8.42158 3.7033 7.57508 3.27244 7.3187 2.48453C7.06232 1.69663 7.49318 0.850127 8.28108 0.593746C12.5761 -0.816664 17.2993 0.315787 20.4878 3.52047Z"
                fill="#DFE1E5"
              />
            </svg>
          )}
        </button>
      </form>
      <div className="legal">
        <p className="legal__privacy">
          Ao se inscrever você concorda com a{' '}
          <a
            href="https://www.iubenda.com/privacy-policy/50449637"
            target="_blank"
            rel="noreferrer"
          >
            Declaração de Privacidade.
          </a>{' '}
          e{' '}
          <a
            href="https://www.iubenda.com/privacy-policy/50449637/cookie-policy"
            target="_blank"
            rel="noreferrer"
          >
            Política de Cookies.
          </a>
        </p>
        <RecaptchaPolicies className="legal__disclaimer" />
        {formState === 'success' && (
          <div className="success">
            <span>
              Obrigado pelo interesse! Você receberá um e-mail com o link para o
              download da ementa do curso.
              <br />
              Enquanto isso, que tal entrar na nossa comunidade do{' '}
              <a href="/#">Discord</a>?
            </span>
          </div>
        )}
        {formState === 'error' && (
          <div className="error">
            <span>
              Ops! Tivemos um problema. Tente novamente, por favor! Se o
              problema persistir, entre em contato conosco.
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
