import React, { useEffect, useCallback } from 'react';

const recaptchaSiteKey = '6LfV1SIoAAAAAATRps0TOqOKP13mUsQsq1rwInES';

export default ({ action }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}`;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const getRecaptchaToken = useCallback(
    () =>
      Promise.race([
        new Promise((_, reject) =>
          setTimeout(() => reject(new Error('recaptcha request timeout')), 3000)
        ),
        new Promise((resolve) =>
          window.grecaptcha.ready(() => {
            window.grecaptcha
              .execute(recaptchaSiteKey, { action })
              .then((token) => {
                resolve(token);
              });
          })
        ),
      ]),
    [action]
  );

  return { getRecaptchaToken };
};

export const RecaptchaPolicies = ({ className }) => (
  <div className={className}>
    This site is protected by reCAPTCHA and the Google{' '}
    <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
    <a href="https://policies.google.com/terms">Terms of Service</a> apply.
  </div>
);
