import './style.scss';
import Images from '../../resources/imgs';

export default function Footer() {
  return (
    <div className="footer">
      <div className="social">
        <p>
          Quer saber ainda mais? Junte-se às nossas comunidades e comece a
          interagir conosco hoje mesmo.
        </p>
        <div className="social__medias">
          <img src={Images.Discord} alt="Discord" />
          <img src={Images.Instagram} alt="Instagram" />
          <img src={Images.WhatsApp} alt="Whatsapp" />
          <img src={Images.X} alt="X (Twitter)" />
          <img src={Images.TikTok} alt="Tiktok" />
        </div>
      </div>
    </div>
  );
}
