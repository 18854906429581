import './resources/styles/global.scss';
import BodyContainer from './components/BodyContainer';
import MainSection from './components/MainSection';
import Footer from './components/Footer';
import Header from './components/Header';
import MainContent from './components/MainContent';

function App() {
  return (
    <>
      <BodyContainer>
        <MainSection>
          <Header />
          <MainContent />
        </MainSection>
        <Footer />
      </BodyContainer>
    </>
  );
}

export default App;
