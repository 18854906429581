import './style.scss';
import Images from '../../resources/imgs';
import { gtag } from '../../utils/analytics';

export default function Header() {
  return (
    <div className="header">
      <div className="logo-wrapper">
        <img className="logo" src={Images.Logo} alt="Curso Full-Stack Mobile" />
      </div>
      <a href="https://lutick.com" target="_blank" rel="noreferrer">
        <img
          className="lutick"
          src={Images.Lutick}
          alt="Lutick"
          onClick={() => gtag('event', 'lutick_logo_click')}
        />
      </a>
    </div>
  );
}
