import background from './Bg.jpg';
import logo from './logo.svg';
import lutick from './lutick.svg';
import discord from './discord.svg';
import instagram from './instagram.svg';
import linkedin from './linkedin.svg';
import x from './x.svg';
import whatsApp from './whatsapp.svg';
import tikTok from './tiktok.svg';
import checkMark from './fi-br-check.svg';
import shareThumb from './share/share_og.jpg';
import ins1 from './ins1.png';
import ins2 from './ins2.png';

const Images = {
  Background: background,
  Logo: logo,
  Lutick: lutick,
  Discord: discord,
  Instagram: instagram,
  Linkedin: linkedin,
  X: x,
  WhatsApp: whatsApp,
  TikTok: tikTok,
  CheckMark: checkMark,
  ShareThumb: shareThumb,
  Ins1: ins1,
  Ins2: ins2,
};

export default Images;
